initMap = function() {
  const center = { lat:  52.950001, lng: -1.150000 };
  
  const practices_data = $("#map").data('practices');
  const practices = practices_data["practices"]
  
  var maxLat = Math.atan(Math.sinh(Math.PI)) * 180 / Math.PI;
  var myLatLng = new google.maps.LatLng(-24, 123);
  
  var user_location;

  if(navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function(position) {
      user_location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
      map.setCenter(user_location);  
    });

   } else {
    user_location = center;
  }   
  
  var map = new google.maps.Map(document.getElementById("map"), {
    zoom: 6.7,
    center: center,
    restriction: {
      latLngBounds: {north: maxLat, south: -maxLat, west: -180, east: 180},
      strictBounds: true
    },
    scrollwheel: false
  });

  const whiteIcon = {
    url:  "assets/white-marker.png",
    scaledSize : new google.maps.Size(56, 62)
  }

  var i;
  var allMarkers = [];

  for (i = 0; i < practices.length; i++) {
    
    const marker = new google.maps.Marker({
      position: practices[i]["coordinates"],
      map: map,
      icon: whiteIcon
    });
    
    allMarkers.push(marker);

    const practiceId      = practices[i]["id"]
    const practiceName    = practices[i]["name"]
    const practiceAddress = practices[i]["address"]

    const contentString =
    '<div id="content">' +
    '<div id="siteNotice">' +
    "</div>" +
    '<div id="bodyContent">' +
    "<p class= practice-name><b>" + "<img class= 'booking-company-image' src='/assets/company-short-logo.svg' width='26.85px' height= '29px'/>" + practiceName + "</b></p>" +
    "<p><b>" + practiceAddress + "</b></p>" +
    "<p><a class='btn btn-book-appointment font' href='/appointments/new?practice_id=" + practiceId + "' >" +
    "Book appointment" + "</a>" +
    "</p>" +
    "</div>" +
    "</div>";

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    var blueIcon = {
      url:  "assets/blue-marker.png",
      scaledSize : new google.maps.Size(56, 62)
    }     

    marker.addListener("click", () => {
      marker.setIcon(blueIcon); 
      infowindow.open(map, marker);
    });

    google.maps.event.addListener(infowindow,'closeclick',function(){
      marker.setIcon(whiteIcon); 
    });
    updateMap();
  }
  
  function updateMap() {
    const input = document.getElementById("pac-input");
    const searchBox = new google.maps.places.SearchBox(input);
    // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });

    // Below commented code concerns marker image, now is hidden, search show only map not marker
    // const searchMarkers = [];

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }
      // searchMarkers.forEach((marker) => {
      //   marker.setMap(null);
      // });

      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }
        // const icon = {
        //   url: place.icon,
        //   size: new google.maps.Size(71, 71),
        //   origin: new google.maps.Point(0, 0),
        //   anchor: new google.maps.Point(17, 34),
        //   scaledSize: new google.maps.Size(38, 38),
        // };

        // searchMarkers.push(
        //   new google.maps.Marker({
        //     map,
        //     icon,
        //     title: place.name,
        //     position: place.geometry.location,
        //   })
        // );

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  }
  
  var markerCluster = new MarkerClusterer(map, allMarkers, {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  });

  $(document).ready(function() {
      
    $('body').on("click", ".btn-book-appointment" , function(e){
      var button = $(e.target);

      $.ajax({
        url: button.attr('href'),
        data: {},
        success: function(data){
          $("#modal-box").html(data);
          $(".modal-book-appointment").modal();

          var mapDiv = $('#map div').first().get(0);
          mapDiv.appendChild($(".modal-book-appointment").get(0));
        }
      });
      return false;
    });

    $('body').on("click", ".btn-book-cancel" , function(e){
      $('.modal').modal('hide');
      return false;
    });
  });
}
  