
function handle_subscriptions(){
  var buttons = $('.payment-buttons');

  buttons.each(function(){
    var button = $(this);

    button.on('click', function(){
      var plan  = $(this).data('plan');
      var items =  [ { plan: plan, quantity: 1 } ];
      var successUrl = stripeRedirectionDomain + '?stripe_client_reference_id=' + stripeClientReferenceId + "&sku=" + plan;
      var cancelUrl  = stripeRedirectionDomain;
      var stripe = Stripe(stripePublic);

      stripe.redirectToCheckout({
        items: items,
        successUrl: successUrl,
        cancelUrl: cancelUrl,
        clientReferenceId: stripeClientReferenceId,
        customerEmail: customerEmail
      });
    })
  });
}

$(document).ready(function() {
  handle_subscriptions()
});
