$(document).ready(function() {

  $('.js-agree').on('click', function(e){
    if($(this).attr('disable-double-click')){
      e.stopPropagation();
      return false;
    }
    $(this).attr('disable-double-click', true);
    
  });

});



