require("@rails/ujs").start()

require('bootstrap')
require("jquery")
require("packs/dashboard")
require('packs/stripe_subscription')
require("packs/modal_data_processing")
require("packs/password_eye")
require("packs/tooltips")
require("packs/appointment")
require("packs/gp_programs")
require("packs/disclaimer")
require("packs/email_subscriptions")
