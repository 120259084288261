$(document).ready(function() {
  handleMainTab();
  handleBodyRegionDropdown();
});


function handleBodyRegionDropdown() {
  $('body').on('change', '#body_region', function(){
    var body_region = $(this).val()

    if(body_region == "all"){
      $("*[data-body-region]").removeClass('hide-me-please');
    } else {
      $("*[data-body-region]").addClass('hide-me-please');
      $("[data-body-region='" + body_region + "']").removeClass('hide-me-please');
    }
  })
}

function handleMainTab() {
  $('body').on("click", ".gp-exercise" , function(e){
    $('.exercise-programs').removeClass('hide-gp-programs');
    $('.strength-programs').addClass('hide-gp-programs');
    $('.general-advice').addClass('hide-gp-programs');
    $('.stay-healthy').addClass('hide-gp-programs');
    $(this).addClass('tab-one');
    $(this).removeClass('tab-one-white');
    $('.gp-strength').removeClass('tab-one');
    $('.gp-strength').addClass('tab-one-white');
    $('.body-region-dropdown').removeClass('hide-me-please');
  });

  $('body').on("click", ".gp-strength" , function(e){
    $('.strength-programs').removeClass('hide-gp-programs');
    $('.exercise-programs').addClass('hide-gp-programs');
    $('.general-advice').removeClass('hide-gp-programs');
    $('.stay-healthy').removeClass('hide-gp-programs');
    $(this).addClass('tab-one');
    $(this).removeClass('tab-one-white');
    $('.gp-exercise').removeClass('tab-one');
    $('.gp-exercise').addClass('tab-one-white');
    $('.body-region-dropdown').addClass('hide-me-please');
  });
}
