$(document).ready(function() {
  data_processing_modal_ready();
});

function data_processing_modal_ready(){

  if ($('.modal-data-processing').length) {
    $(".modal-data-processing").modal();
  }

}
