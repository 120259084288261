function show_password_ready(){
  $('.sign-eye-close').click(function(e) {
    $(e.target).toggleClass('eye-hide');
    $(e.target).parent().find('.sign-eye-open').toggleClass('eye-hide');

    $(e.target).parent().find('.password').prop("type",  "text");
  });
  $('.sign-eye-open').click(function(e) {
    $(e.target).toggleClass('eye-hide');
    $(e.target).parent().find('.sign-eye-close').toggleClass('eye-hide');

    $(e.target).parent().find('.password').prop("type",  "password");
  });
}
function show_password_settings_ready(){
  $('.sign-eye-close-settings').click(function(e) {
    $(e.target).toggleClass('eye-hide');
    $(e.target).parent().find('.sign-eye-open-settings').toggleClass('eye-hide');

    $(e.target).parent().find('.password').prop("type",  "text");
  });
  $('.sign-eye-open-settings').click(function(e) {
    $(e.target).toggleClass('eye-hide');
    $(e.target).parent().find('.sign-eye-close-settings').toggleClass('eye-hide');

    $(e.target).parent().find('.password').prop("type",  "password");
  });
}


$(document).ready(function() {
  show_password_ready();
  show_password_settings_ready();
});
