function handle_dashboard_carousel() {
  $('.get-help').tooltip({ followPointer: true });
  $('.stay-strong').tooltip({ followPointer: true });
  $('.book-physio').tooltip({ followPointer: true });

  function renderProblemAdviceSection(index, n=0) {
    if(!!!$('.dashboard-container').length){
      return;
    }

    var i;

    var images = $(".dashboard-container").data('dashboard').problem_advices

    var generate_images;

    if (images.length < 3 ) {
      generate_images = [images[0], images[1]]
    } else {

      if (n === 1) {
        if (index === images.length) { slideIndex = 0 }

        if (slideIndex === images.length - 2) {
          generate_images = [images[slideIndex], images[slideIndex+1], images[0]]
        } else if (slideIndex === images.length - 1)  {
          generate_images = [images[slideIndex], images[0], images[1]]

        }
        else {
          generate_images = [images[slideIndex], images[slideIndex+1], images[slideIndex+2]]
        }
      }

      if (n === 0) {
        generate_images = [images[slideIndex], images[slideIndex+1], images[slideIndex+2]]
      }

      if (n === -1) {
        if (index === -1) { slideIndex = images.length - 1 }

        if (slideIndex === images.length - 1) {
          generate_images = [images[slideIndex], images[0], images[1]]
        } else if (slideIndex === images.length - 2) {
          generate_images = [images[slideIndex], images[slideIndex + 1], images[0]]
        }
        else {
          generate_images = [images[slideIndex], images[slideIndex + 1], images[slideIndex + 2]]
        }
      }
    }

    if (images.length > 2 ) {

      var urlImage1   = generate_images[0]['image_url']
      var urlImage2   = generate_images[1]['image_url']
      var urlImage3   = generate_images[2]['image_url']

      var titleImage1 = generate_images[0]['title']
      var titleImage2 = generate_images[1]['title']
      var titleImage3 = generate_images[2]['title']

      var pdf1        = generate_images[0]['pdf_url']
      var pdf2        = generate_images[1]['pdf_url']
      var pdf3        = generate_images[2]['pdf_url']

      $('.habox1').find('.item').css({ "background-image": "url('" + urlImage1 + "')"});
      $('.habox1').find('.text').text(titleImage1);
      $('.ha1').attr('href', pdf1)

      $('.habox2').find('.item').css({ "background-image": "url('" + urlImage2 + "')"});
      $('.habox2').find('.text').text(titleImage2);
      $('.ha2').attr('href', pdf2)

      $('.habox3').find('.item').css({ "background-image": "url('" + urlImage3 + "')"});
      $('.habox3').find('.text').text(titleImage3);
      $('.ha3').attr('href', pdf3)

    } else if (images.length === 2 ) {
      var urlImage1   = generate_images[0]['image_url']
      var urlImage2   = generate_images[1]['image_url']

      var titleImage1 = generate_images[0]['title']
      var titleImage2 = generate_images[1]['title']

      var pdf1        = generate_images[0]['pdf_url']
      var pdf2        = generate_images[1]['pdf_url']

      $('.habox1').find('.item').css({ "background-image": "url('" + urlImage1 + "')"});
      $('.habox1').find('.text').text(titleImage1);
      $('.ha1').attr('href', pdf1)

      $('.habox2').find('.item').css({ "background-image": "url('" + urlImage2 + "')"});
      $('.habox2').find('.text').text(titleImage2);
      $('.ha2').attr('href', pdf2)

      $('.helpful-advice-section').find('.cont3').css({'visibility': 'hidden'});
      $('.helpful-advice-buttons').css({'visibility': 'hidden'});

    } else if (images.length === 1) {
      var urlImage1   = generate_images[0]['image_url']
      var titleImage1 = generate_images[0]['title']
      var pdf1        = generate_images[0]['pdf_url']

      $('.habox1').find('.item').css({ "background-image": "url('" + urlImage1 + "')"});
      $('.habox1').find('.text').text(titleImage1);
      $('.ha1').attr('href', pdf1)

      $('.helpful-advice-section').find('.cont2').css({'visibility': 'hidden'});
      $('.helpful-advice-section').find('.cont3').css({'visibility': 'hidden'});
      $('.helpful-advice-buttons').css({'visibility': 'hidden'});
    } else {
      $('.helpful-advice-section').find('.cont1').css({'visibility': 'hidden'});
      $('.helpful-advice-section').find('.cont2').css({'visibility': 'hidden'});
      $('.helpful-advice-section').find('.cont3').css({'visibility': 'hidden'});
      $('.helpful-advice-buttons').css({'visibility': 'hidden'});
    }
  }

  function moveProblemAdvices(n) {
    renderProblemAdviceSection(slideIndex += n, n);
  }

  function movePrograms(n) {
    showPrograms(programIndex += n, n);
  }

  var slideIndex = 0;
  renderProblemAdviceSection(slideIndex);

  var programIndex = 0;
  showPrograms(programIndex);


  $('body').on('click', '.go-left', function(){
    moveProblemAdvices(-1)
  })

  $('body').on('click', '.go-right', function(){
    moveProblemAdvices(1)
  })

  $('body').on('click', '.go-left-program', function(){
    movePrograms(-1)
  })

  $('body').on('click', '.go-right-program', function(){
    movePrograms(1)
  })

  $('body').on('click', '.close-program', function(e){
    var clicked = $(e.target);
    var url = clicked.parent().attr('href');

    $('.modal-close-program').modal();
    $('.modal-close-program .close_program_url').attr('href', url);
    return false;
  })

  $('body').on('click', '.cancel-subscription', function(e){
    $('.modal-subscription-cancellation').modal();
    return false;
  })

  function showPrograms(index, n=0) {
    if(!!!$('.dashboard-container').length){
      return;
    }

    var i;

    var programs = $(".dashboard-container").data('dashboard').programs

    var generate_programs;

    if (programs.length < 3 ) {
      generate_programs = [programs[0], programs[1]]
    } else {

      if (n === 1) {
        if (index === programs.length) { programIndex = 0 }

        if (programIndex === programs.length - 1)  {
          generate_programs = [programs[programIndex], programs[0]]
        }
        else {
          generate_programs = [programs[programIndex], programs[programIndex+1]]
        }
      }

      if (n === 0) {
        generate_programs = [programs[programIndex], programs[programIndex+1]]
      }

      if (n === -1) {
        if (index === -1) { programIndex = programs.length - 1 }

        if (programIndex === programs.length - 1) {
           generate_programs = [programs[programIndex], programs[0]]
        } else {
          generate_programs = [programs[programIndex], programs[programIndex + 1]]
        }
      }
    }

    if (programs.length > 1) {
      var programShortTitle  = generate_programs[0]['program-short-title']
      var programTitle       = generate_programs[0]['program-title']
      var programDescription = generate_programs[0]['program-description']
      var color              = generate_programs[0]['color']
      var pdf                = generate_programs[0]['pdf_url']
      var logoUrl            = generate_programs[0]['logo_url']
      var close_program_url  = generate_programs[0]['close_program_url']

      $('.program-short-title1').text(programShortTitle);
      $('.program-short-title1').css({'background-color': color})
      $('.program-title1').text(programTitle);
      $('.program-description1').text(programDescription);
      $('.pdf1').attr('href', pdf)
      $('.program-logo1').attr('src', logoUrl)
      $('.close_program_url1').attr('href', close_program_url)

      if (logoUrl.includes('mobility5')) {
        $('.program-logo1').addClass('mobility-logo-smaller');
      } else {
        $('.program-logo1').removeClass('mobility-logo-smaller');
      }

      var programShortTitle1  = generate_programs[1]['program-short-title']
      var programTitle1       = generate_programs[1]['program-title']
      var programDescription1 = generate_programs[1]['program-description']
      var color1              = generate_programs[1]['color']
      var pdf                 = generate_programs[1]['pdf_url']
      var logoUrl             = generate_programs[1]['logo_url']
      var close_program_url   = generate_programs[1]['close_program_url']

      $('.program-short-title2').text(programShortTitle1);
      $('.program-short-title2').css({'background-color': color1})
      $('.program-title2').text(programTitle1);
      $('.program-description2').text(programDescription1);
      $('.pdf2').attr('href', pdf)
      $('.program-logo2').attr('src', logoUrl)
      $('.close_program_url2').attr('href', close_program_url)

      if (logoUrl.includes('mobility5')) {
        $('.program-logo2').addClass('mobility-logo-smaller');
      } else {
        $('.program-logo2').removeClass('mobility-logo-smaller');
      }


      $('.add-program-item').css({'display': 'none'});
    } else if (programs.length === 1) {

      var programShortTitle  = generate_programs[0]['program-short-title']
      var programTitle       = generate_programs[0]['program-title']
      var programDescription = generate_programs[0]['program-description']
      var color              = generate_programs[0]['color']
      var pdf                = generate_programs[0]['pdf_url']
      var logoUrl            = generate_programs[0]['logo_url']
      var close_program_url  = generate_programs[0]['close_program_url']

      $('.program-short-title1').text(programShortTitle);
      $('.program-short-title1').css({'background-color': color, 'border-color': color})
      $('.program-title1').text(programTitle);
      $('.program-description1').text(programDescription);
      $('.pdf1').attr('href', pdf)
      $('.program-logo1').attr('src', logoUrl)
      $('.close_program_url1').attr('href', close_program_url)

      if (logoUrl.includes('mobility5')) {
        $('.program-logo1').addClass('mobility-logo-smaller');
      } else {
        $('.program-logo1').removeClass('mobility-logo-smaller');
      }

      $('.program-buttons').css({'visibility': 'hidden'});
      $('.program-item2').css({'display': 'none'});
    } else {
      $('.program-buttons').css({'visibility': 'hidden'});
      $('.program-item1').css({'display': 'none'});
      $('.program-item2').css({'display': 'none'});

      $('.add-program-item').css({'height': '100%'});
    }

    if (programs.length === 2) {
      $('.program-buttons').css({'visibility': 'hidden'});
    }
    if (programs.length === 0) {
      $('.add-program-item').css({'height': '250px'});
    }
  }
}

$(document).ready(function(){
  handle_dashboard_carousel()
})
